import { Auth } from 'aws-amplify';
import React, { useContext, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PATHS as AppraisalPaths } from '../../api/Appraisal/appraisalAPIs';
import { PATHS as AuditPaths } from '../../api/AuditLogs/AuditLogsAPIs';
import { PATHS as ContractPaths } from '../../api/Contract/contractAPIs';
import { PATHS as DocumentPaths } from '../../api/Document/DocumentAPI';
import { PATHS as InterviewPaths } from '../../api/Interview/interviewAPIs';
import { PATHS as InvoicePaths } from '../../api/Invoice/invoiceAPIs';
import { PATHS as LinkedInPaths } from '../../api/LinkedInContacts/linkedInAPIs';
import { PATHS as OnBoardingPaths } from '../../api/OnBoarding/onboardingAPIs';
import { PATHS as OpportunityPaths } from '../../api/Opportunity/opportunityAPIs';
import { PATHS as PolicyPaths } from '../../api/Policy/policyAPIs';
import { PATHS as ProjectPaths } from '../../api/Project/projectAPIs';
import { PATHS as ResourcePaths } from '../../api/Resource/resourceAPIs';
import { PATHS as RevenuePaths } from '../../api/Revenue/revenueAPIs';
import { PATHS as SearchPaths } from '../../api/Search/searchAPIs';
import { PATHS as TicketPaths } from '../../api/Ticket/TicketAPIs';
import { PATHS as vacanciesPaths } from '../../api/Vacancies/vacanciesAPIs';
import { PATHS as hardwarePaths } from '../../api/Hardware/hardwareAPIs';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { GlobalContext } from '../../contexts/GlobalContext';
import DefaultLayout from '../../layouts/DefaultLayout';
import { checkUserLogin, setUserAttributes } from '../../utils/auth';
import AllAccounts from '../Account/AllAccounts';
import AllAppraisals from '../Appraisal/AllAppraisals';
import KraTemplateDetails from '../Appraisal/KraTemplateDetails';
import AllAuditLogs from '../AuditLogs/AllAuditLogs';
import AllContacts from '../Contact/AllContacts';
import AllContracts from '../Contract';
import ContractDetail from '../Contract/ContractDetail';
import AllCustomers from '../Customer/AllCustomers';
import AllCandidates from '../Documents/AllCandidates';
import AllEmployees from '../Documents/AllEmployees';
import AllInterns from '../Documents/AllInterns';
import GenerateDocuments from '../Documents/GenerateDocuments';
import InternshipLetterPage from '../Documents/InternshipLetterPage';
import OfferLetterPage from '../Documents/OfferLetterPage';
import EmailAiasesList from '../Home/EmailAliasesList';
import HolidayList from '../Home/HolidayList';
import Home from '../Home/Home';
import AllInterviews from '../Interview/AllInterviews';
import InterviewDetails from '../Interview/InterviewDetails';
import OfferedCandidateList from '../Interview/OfferedCandidateList';
import AllInvoices from '../Invoice';
import InvoiceDetail from '../Invoice/InvoiceDetail';
import AllLinkedInContacts from '../LinkedIn/AllLinkedInContacts';
import Login from '../Login/Login';
import NewCandidateLogin from '../NewCandidates/NewCandidateLogin';
import NewCandidates from '../NewCandidates/NewCandidates';
import NotFound from '../NotFound/NotFound';
import AddEmployee from '../OnBoardingManagement/AddEmployee';
import AllOnBoardings from '../OnBoardingManagement/AllOnBoardings';
import AllCustomerRelations from '../Opportunity';
import CRMProjectDetail from '../Opportunity/OpportunityDetail';
import PolicyStore from '../Policy/PolicyStore';
import Profile from '../Profile/Profile';
import ProfileDocumentPage from '../Profile/ProfileDocumentPage';
import ProfileTeamPage from '../Profile/ProfileTeamPage';
import AllProjects from '../Project';
import ProjectDetails from '../Project/ProjectDetail';
import AllResources from '../Resource/AllResources';
import ViewByProjectList from '../Resource/ViewByProjectList';
import ViewBySkillList from '../Resource/ViewBySkillList';
import AllRevenues from '../Revenue';
import RevenueDetail from '../Revenue/RevenueDetail';
import AllSearch from '../Search/AllSearch';
import CategoryList from '../Ticket/CategoryList';
import DashBoard from '../Ticket/DashBoard';
import AllTimesheet from '../Timesheet/AllTimesheet';
import Workspace from '../Workspace';
import AllVacancies from '../vacancies/AllVacancies';
import Hardware from '../Hardware/Hardware';

interface ProtectedRouteProps {
  isPrivate?: boolean;
  children: JSX.Element;
  module: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isPrivate, children, module }) => {
  const {
    isAuthenticated,
    isAuthenticating,
    setIsAuthenticating,
    isFetchingPermissions,
    setIsAuthenticated,
    checkAccess,
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticating, isAuthenticated]);

  const checkAuthState = () => {
    try {
      setIsAuthenticating(true);
      checkUserLogin()
        .then(({ username, attributes }) => {
          Auth.currentSession().then((session) => {
            const token = session.getAccessToken().getJwtToken();
            setUserAttributes(username, attributes, token);
            setIsAuthenticated(true);
          });
        })
        .catch(() => setIsAuthenticated(false))
        .finally(() => setIsAuthenticating(false));
    } catch (err) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    // Logic to check if user is Authenticated.
    checkAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticating || isFetchingPermissions) {
    return <ProgressSpinner showSpinner={isAuthenticating || isFetchingPermissions} />;
  }

  // UnAuthenticated user trying to access protected routes
  if (isPrivate && !isAuthenticated) {
    return <Navigate replace to="/login" />;
  }

  // eslint-disable-next-line no-nested-ternary
  return checkAccess(module) ? (
    isPrivate ? (
      <DefaultLayout>{children}</DefaultLayout>
    ) : (
      children
    )
  ) : (
    <NotFound />
  );
};

ProtectedRoute.defaultProps = {
  isPrivate: true,
};

export interface Route {
  readonly path: string;
  readonly element: any;
}

export const ROUTES: Route[] = [
  {
    path: '/login',
    element: (
      <ProtectedRoute module="" isPrivate={false}>
        <Login />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile',
    element: (
      <ProtectedRoute module="">
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile/team',
    element: (
      <ProtectedRoute module="">
        <ProfileTeamPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile/document/:id',
    element: (
      <ProtectedRoute module="">
        <ProfileDocumentPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/home',
    element: (
      <ProtectedRoute module="">
        <Home />
      </ProtectedRoute>
    ),
  },
  {
    path: '/workspace',
    element: (
      <ProtectedRoute module="">
        <Workspace />
      </ProtectedRoute>
    ),
  },
  {
    path: '/project',
    element: (
      <ProtectedRoute module={ProjectPaths.GET_PROJECT}>
        <AllProjects />
      </ProtectedRoute>
    ),
  },
  {
    path: '/project/:id',
    element: (
      <ProtectedRoute module={ProjectPaths.GET_PROJECT_DETAIL('id')}>
        <ProjectDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: '/revenue/project',
    element: (
      <ProtectedRoute module={RevenuePaths.GET_REVENUE_PROJECT}>
        <AllRevenues />
      </ProtectedRoute>
    ),
  },
  {
    path: '/revenue/project/:id',
    element: (
      <ProtectedRoute module={RevenuePaths.GET_REVENUE_PROJECT_DETAIL('id')}>
        <RevenueDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/invoice',
    element: (
      <ProtectedRoute module={InvoicePaths.GET_INVOICE}>
        <AllInvoices />
      </ProtectedRoute>
    ),
  },
  {
    path: '/invoice/:id',
    element: (
      <ProtectedRoute module={InvoicePaths.GET_OTHER_DETAILS('id')}>
        <InvoiceDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/contract',
    element: (
      <ProtectedRoute module={ContractPaths.GET_CONTRACT}>
        <AllContracts />
      </ProtectedRoute>
    ),
  },
  {
    path: '/contract/project/:id',
    element: (
      <ProtectedRoute module={ContractPaths.GET_CONTRACT_PROJECT_DETAIL('id')}>
        <ContractDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/resource',
    element: (
      <ProtectedRoute module={ResourcePaths.GET_RESOURCES}>
        <AllResources />
      </ProtectedRoute>
    ),
  },
  {
    path: '/resource/:id',
    element: (
      <ProtectedRoute module={ResourcePaths.GET_RESOURCES}>
        <AllResources />
      </ProtectedRoute>
    ),
  },
  {
    path: '/resource/viewBySkill',
    element: (
      <ProtectedRoute module={ResourcePaths.GET_RESOURCES}>
        <ViewBySkillList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/resource/viewByProject',
    element: (
      <ProtectedRoute module={ResourcePaths.GET_RESOURCES}>
        <ViewByProjectList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/opportunity',
    element: (
      <ProtectedRoute module={OpportunityPaths.CRM_OPPORTUNITY}>
        <AllCustomerRelations />
      </ProtectedRoute>
    ),
  },
  {
    path: '/opportunity/:id',
    element: (
      <ProtectedRoute module={OpportunityPaths.GET_CRM_OPPORTUNITY_DETAIL('id')}>
        <CRMProjectDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/account',
    element: (
      <ProtectedRoute module={OpportunityPaths.CRM_ACCOUNT}>
        <AllAccounts />
      </ProtectedRoute>
    ),
  },
  {
    path: '/account/:id',
    element: (
      <ProtectedRoute module={OpportunityPaths.CRM_ACCOUNT}>
        <AllAccounts />
      </ProtectedRoute>
    ),
  },
  {
    path: '/contact',
    element: (
      <ProtectedRoute module={OpportunityPaths.CRM_CONTACT}>
        <AllContacts />
      </ProtectedRoute>
    ),
  },
  {
    path: '/contact/:id',
    element: (
      <ProtectedRoute module={OpportunityPaths.CRM_CONTACT}>
        <AllContacts />
      </ProtectedRoute>
    ),
  },
  {
    path: '/customer',
    element: (
      <ProtectedRoute module={OpportunityPaths.CRM_CUSTOMER}>
        <AllCustomers />
      </ProtectedRoute>
    ),
  },
  {
    path: '/customer/:id',
    element: (
      <ProtectedRoute module={OpportunityPaths.CRM_CUSTOMER}>
        <AllCustomers />
      </ProtectedRoute>
    ),
  },
  {
    path: '/onboarding',
    element: (
      <ProtectedRoute module={OnBoardingPaths.GET_EMPLOYEE}>
        <AllOnBoardings />
      </ProtectedRoute>
    ),
  },
  {
    path: '/onboarding/addEmployee',
    element: (
      <ProtectedRoute module={OnBoardingPaths.GET_EMPLOYEE}>
        <AddEmployee />
      </ProtectedRoute>
    ),
  },
  {
    path: '/onboarding/candidate/:token',
    element: (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_NEW_CANDIDATE_LOGIN_CLIENT_ID || ''}>
        <NewCandidateLogin />
      </GoogleOAuthProvider>
    ),
  },
  {
    path: '/onboarding/addCandidate/:id',
    element: <NewCandidates />,
  },
  {
    path: '/onboarding/addEmployee/:id/:status',
    element: (
      <ProtectedRoute module={OnBoardingPaths.GET_EMPLOYEE_BY_ID('id')}>
        <AddEmployee />
      </ProtectedRoute>
    ),
  },
  {
    path: '/appraisal',
    element: (
      <ProtectedRoute module={AppraisalPaths.GET_APPRAISAL}>
        <AllAppraisals />
      </ProtectedRoute>
    ),
  },
  {
    path: '/appraisal/startAppraisal',
    element: (
      <ProtectedRoute module={AppraisalPaths.GET_APPRAISAL}>
        <KraTemplateDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: '/appraisal/kraDetails/:id',
    element: (
      <ProtectedRoute module={AppraisalPaths.GET_APPRAISAL}>
        <KraTemplateDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: '/document',
    element: (
      <ProtectedRoute module={DocumentPaths.GET_DOCUMENT}>
        <GenerateDocuments />
      </ProtectedRoute>
    ),
  },
  {
    path: '/document/offerLetter',
    element: (
      <ProtectedRoute module={DocumentPaths.OFFER_LETTER}>
        <AllCandidates />
      </ProtectedRoute>
    ),
  },
  {
    path: '/document/offerLetter/:id',
    element: (
      <ProtectedRoute module={DocumentPaths.OFFER_LETTER}>
        <OfferLetterPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/document/offerLetter/newOfferLetter',
    element: (
      <ProtectedRoute module={DocumentPaths.OFFER_LETTER}>
        <OfferLetterPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/document/compensationLetter',
    element: (
      <ProtectedRoute module={DocumentPaths.OFFER_LETTER}>
        <AllEmployees />
      </ProtectedRoute>
    ),
  },
  {
    path: '/document/compensationLetter/:id',
    element: (
      <ProtectedRoute module={DocumentPaths.OFFER_LETTER}>
        <OfferLetterPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/document/internshipLetter',
    element: (
      <ProtectedRoute module={DocumentPaths.GET_INTERNSHIP}>
        <AllInterns />
      </ProtectedRoute>
    ),
  },
  {
    path: '/document/internshipLetter/:id',
    element: (
      <ProtectedRoute module={DocumentPaths.INTERNSHIP_LETTER}>
        <InternshipLetterPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/document/internshipLetter/newInternshipLetter',
    element: (
      <ProtectedRoute module={DocumentPaths.INTERNSHIP_LETTER}>
        <InternshipLetterPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/auditLog',
    element: (
      <ProtectedRoute module={AuditPaths.GET_AUDITLOGS}>
        <AllAuditLogs />
      </ProtectedRoute>
    ),
  },
  {
    path: '/policy',
    element: (
      <ProtectedRoute module={PolicyPaths.POLICY}>
        <PolicyStore />
      </ProtectedRoute>
    ),
  },
  {
    path: '/search/:id',
    element: (
      <ProtectedRoute module={SearchPaths.EMPLOYEE}>
        <AllSearch />
      </ProtectedRoute>
    ),
  },
  {
    path: '/search/:id/org-tree',
    element: (
      <ProtectedRoute module={SearchPaths.EMPLOYEE}>
        <ProfileTeamPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/home/holiday-calendar',
    element: (
      <ProtectedRoute module="">
        <HolidayList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/home/email-aliases',
    element: (
      <ProtectedRoute module="">
        <EmailAiasesList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/timesheet',
    element: (
      <ProtectedRoute module="">
        <AllTimesheet />
      </ProtectedRoute>
    ),
  },
  {
    path: '/timesheet/:id',
    element: (
      <ProtectedRoute module="">
        <AllTimesheet />
      </ProtectedRoute>
    ),
  },
  {
    path: '/interview',
    element: (
      <ProtectedRoute module={InterviewPaths.GET_CANDIDATES}>
        <AllInterviews />
      </ProtectedRoute>
    ),
  },
  {
    path: '/interview/:id',
    element: (
      <ProtectedRoute module={InterviewPaths.GET_CANDIDATES}>
        <AllInterviews />
      </ProtectedRoute>
    ),
  },
  {
    path: '/interview/details/:id',
    element: (
      <ProtectedRoute module={InterviewPaths.GET_CANDIDATES}>
        <InterviewDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: '/interview/offeredCandidate',
    element: (
      <ProtectedRoute module={InterviewPaths.GET_ALL_INTERVIEWEES}>
        <OfferedCandidateList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ticket',
    element: (
      <ProtectedRoute module={TicketPaths.GET_TICKET}>
        <DashBoard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ticket/category',
    element: (
      <ProtectedRoute module={TicketPaths.GET_CATEGORY}>
        <CategoryList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/linkedInContacts',
    element: (
      <ProtectedRoute module={LinkedInPaths.CONTACT}>
        <AllLinkedInContacts />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Vacancies',
    element: (
      <ProtectedRoute module={vacanciesPaths.VACANCIES}>
        <AllVacancies />
      </ProtectedRoute>
    ),
  },
  {
    path: '/hardware',
    element: (
      <ProtectedRoute module={hardwarePaths.HARDWARES}>
        <Hardware />
      </ProtectedRoute>
    ),
  },
];
