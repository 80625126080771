/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import Datatable from '../../components/Datatable/Datatable';

import I18nKey from '../../translations/I18nKey';
import { generateRows } from '../../components/Datatable/datatableUtils';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import HardwareClient from '../../api/Hardware/hardwareAPIs';
import useHardwareStyles from './HardwareStyles';
import { columns, hardwareIdName } from './HardwareListTableConfig';

const Hardware = () => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const styles = useHardwareStyles();
  const { showSnackbar } = useDisplaySnackbar();

  const intl = useIntl();
  const getTableRows = (data: any) => {
    const formattedData = data.map((el: any, index: number) => ({ key: index, ...el }));
    return generateRows(formattedData, [], [], hardwareIdName);
  };

  const getHardwareeList = () => {
    setIsLoading(true);

    HardwareClient.getHardwareList()
      .then((response: any) => {
        const rows = getTableRows(response?.data);
        setTableRows(rows);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getHardwareeList(), [fetch]);
  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.hardwareWrapper}>
        {isLoading && <ProgressSpinner showSpinner={isLoading} />}
        <Box className={styles.Header}>
          <span>
            {intl.formatMessage({
              id: I18nKey.HARDWARE_TITLE,
            })}
          </span>
        </Box>
        <Box className={styles.ListWrapper}>
          <Datatable rows={tableRows} columns={columns} columnVisibility={true as boolean} />
        </Box>
      </Box>
    </>
  );
};

export default Hardware;
